import Page from "src/components/Page";
import {FileField, FormGroup, Form, SubmitButton, TextField, Recaptcha} from "src/vendor/FormX";
import {Stack} from '@mui/material'

import { alpha, styled } from '@mui/material/styles';
import useAppState from "src/hooks/useAppState";
import SimpleLink from "src/components/SimpleLink";

export default function() {
    const {dispatch} = useAppState();

    const Wrapper = styled(Page)( (theme) => ({
        width: '100%',
    }) );

    const onSuccessfulLogin = async({result}) => {
        //window.location = '/';
    }

    return <Wrapper title={`Belépés`}>
        <Form action='authentication/login' onSuccess={onSuccessfulLogin}>
            <FormGroup label='Belépés'>
                <Stack direction="column" spacing={2}>
                    <TextField label={'Email'} name={'email'} />
                    <TextField label={'Jelszó'} name={'password'} type={'password'} />
                    <Recaptcha name={'recaptcha'} />
                    <Stack justifyContent={'space-evenly'} alignItems={'center'} direction="row" spacing={2}>
                    
                        <SubmitButton label={'Belépés'} />
                        <SimpleLink to="/signup">Új fiók</SimpleLink>
                    </Stack>
                </Stack>
            </FormGroup>
        </Form>
    </Wrapper>
}